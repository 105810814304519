import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import { showSuccessToast, showFailToast } from 'vant';
import Vue from 'vue';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import { Uploader } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import Decimal from 'decimal.js';

// 引入英文语言包

import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  data() {
    return {
      fileList: [],
      url: '',
      lang_list: {},
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      loading: false,
      finished: false,
      is_jia: false,
      page: 1,
      bank: [],
      qb: [],
      select: {
        type: 0
      },
      address: [],
      show11: 0,
      show: false
    };
  },
  computed: {
    result() {
      let num1 = new Decimal(this.select.fabi.lv);
      let num2 = new Decimal(this.select.price);
      return num1.times(num2).toFixed(2);
    }
  },
  created: function () {
    let _this = this;
    _this.get_imdex_data();
    this.code = this.$route.query.code ? this.$route.query.code : '0000';
  },
  methods: {
    onClickLeft() {
      //api.chat(0);
      let _this = this;
      _this.$router.push({
        name: 'rec_list',
        query: {
          id: 0
        }
      });
    },
    back() {
      window.history.back();
    },
    onload: function (name = false) {
      if (this.is_jia) {
        return false;
      }
      this.is_jia = true;
      if (name) {
        this.goods = [];
        this.page = 1;
        this.finished = false;
        this.is_has = 1;
      }
      api.all('/api/user/user_cz', {
        page: this.page
      }, (err, data) => {
        if (!err && data.code === 1) {
          this.loading = false;
          this.is_jia = false;
          let oldArr = this.address;
          let newArr = data.data.dizhi;
          this.address = oldArr.concat(newArr);
          //this.banner = data.data.banner;

          if (data.data.count <= this.page * 20) {
            this.finished = true;
          }
          this.page++;
        } else {
          //console.log(data);
        }
      });
    },
    copyToClipboard(str) {
      if (str == null || str == undefined) {
        showFailToast(this.lang.ap8);
        return;
      }
      navigator.clipboard.writeText(str).then(() => {
        showSuccessToast(this.lang.ap9);
      }).catch(err => {
        showFailToast(this.lang.ap10);
      });
    },
    afterRead: function (file) {
      // 此时可以自行将文件上传至服务器
      //console.log(file);
      // if (this.fileList.length > 1) {
      //   this.$toast.fail('Only upload an image');
      //   this.fileList.splice(1);
      //   return;
      // }
      //  let _this=this
      let fd = new FormData(); // 实例化表单，提交数据使用
      fd.append('type', 'material');
      fd.append('file', file.file); // 将img追加进去
      // fd.append('token', local.readInfo('jwt'));
      api.all('/api/user/upload', fd, (err, data) => {
        //toast1.clear();
        if (data.code == 0) {
          showToast('Picture is too big');
        } else {
          //console.log(err);
          //console.log(data);
          this.url = data.data.url;
        }
      }, {
        onUploadProgress: progressEvent => {
          //console.log(progressEvent);
        }
      });
    },
    onOversize(file) {
      //console.log(file);
      showToast(this.lang.a6);
    },
    get_imdex_data: function () {
      api.all('/api/user/user_rec', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.cate = data.data.cate;
          this.user = data.data.user;
          this.bank = data.data.td;
          this.qb = data.data.qb;
        } else {
          //console.log(data);
        }
      });
    },
    up: function () {
      //  if (this.select.price <= 0 || this.select.price == null || this.select.price == undefined) {
      //    showFailToast(this.lang.a11);
      //    return
      //   }

      //   if(this.url==""){
      //     showFailToast(this.lang.a12);
      //     return
      //   }
      //   if(this.select.address==""||this.select.address==null||this.select.address==undefined){
      //     showFailToast(this.lang.a13);
      //     return
      //   }
      let data = this.select;
      data.url = this.url;
      api.all('/api/user/user_usdt_cz1', data, (err, data) => {
        showToast(data.msg);
        if (data.code == 1) {
          this.show = true;
          this.qb = data.data;
        }
      });
    },
    bank111: function (id) {
      if (this.select.price <= 0 || this.select.price == null || this.select.price == undefined) {
        showFailToast(this.lang.a11);
        return;
      }
      let data = this.select;
      data.td_id = id;
      api.all('/api/user/user_bank_cz', data, (err, data) => {
        showToast(data.msg);
        if (data.code == 1) {
          window.location.href = data.data.url;
        }
      });
    },
    de: function (id) {
      api.all('/api/user/de_reward', {
        id: id
      }, (err, data) => {
        showToast(data.msg);
        this.get_imdex_data();
      });
    },
    show_d(type) {
      api.all('/api/user/user_rec', {
        gz_id: this.guize.data.id
      }, (err, data) => {
        showToast(data.msg);
        this.get_imdex_data();
      });
    },
    detail: function (id1) {
      let _this = this;
      _this.$router.push({
        name: 'detail',
        query: {
          id: id1
        }
      });
    },
    chat: function (type = 0) {
      api.chat(type);
    }
  }
};